import React from "react"
import { useForm } from "react-hook-form"

export default function Newsletter() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  return (
    <div className="bg-[#F6EFEC] py-12 md:py-20">
      <div className="container mx-auto px-2 lg:px-8">
        <div className="flex flex-col gap-4 items-center">
          <h4 className="text-2xl md:text-4xl font-semibold text-stone-800">
            Subscribe to our newsletter
          </h4>
          <p className="text-sm text-stone-400">
            Get Ibiza news, Announcement and the inside track
          </p>
          <div className="max-w-md mx-auto w-full">
            <form
              onSubmit={handleSubmit(data => console.log(data))}
              className="w-full grid relative"
            >
              <div className="w-full">
                <input
                  type="email"
                  {...register("email", { required: true })}
                  placeholder="Johndoe@example.com"
                  className={`w-full px-8 py-3 rounded-full bg-white outline-none focus:outline-none border-2 ${
                    errors["email"] ? "border-rose-400" : "border-stone-200"
                  }`}
                />
              </div>
              <div className="absolute right-2 top-1.5">
                <button
                  type="submit"
                  className="px-8 py-2 rounded-full bg-[#223C4F] text-white"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
