import React from "react"

export default function Card({ image, children }) {
  return (
    <div className="rounded-xl border overflow-hidden bg-white">
      <div className="aspect-video object-cover">{image}</div>
      <div className="flex flex-col gap-3 p-3">{children}</div>
    </div>
  )
}
