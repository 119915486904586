import React from "react"

import { graphql, Link } from "gatsby"
import Layout from "../components/new-design/Layout"
import Seo from "../components/seo"

import Expandable from "../components/new-design/Expandable"
import Newsletter from "../components/new-design/Newsletter"
import HeadSection from "../components/new-design/HeadSection"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/new-design/Card"
import { FaSun, FaUmbrellaBeach, FaUtensilSpoon } from "react-icons/fa"
export default function Category({ data, location }) {
  return (
    <Layout>
      <Seo title="Guides" pathname={location.pathname} />
      <HeadSection
        tagline="Tour Guide"
        title="Your Guide to Ibiza"
        subtitle="We've created a curated guide to everything Ibiza has to offer."
      />
      <Categories content={data.allSanityListingCategory.nodes} />
      <BrowseByArea content={data.allSanityArea.nodes} />
      <MoreCategories content={data.allSanityListingCategory.nodes} />
      <Newsletter />
    </Layout>
  )
}

const Categories = ({ content }) => {
  return (
    <div className="max-w-6xl w-5/6 2xl:w-full mx-auto py-8">
      <div className="grid gap-4 md:grid-cols-3">
        {CategoryPicture.map((cata, key) => {
          return (
            // cata.featured && (
            // <li
            //   key={key}
            //   className="w-full flex items-center justify-center h-[250px] bg-stone-800 rounded-2xl overflow-hidden"
            // >
            //   <Link to={`${cata.slug.current}/`}>
            //     <h3 className="text-white">{cata.name}</h3>
            //   </Link>
            // </li>
            <Card key={key} image={<Link to={`${cata.to}`}>{cata.image}</Link>}>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <span className="h-7 w-7 rounded-full border flex items-center justify-center">
                    {cata.icon}
                  </span>
                  <Link to={`${cata.to}`}>
                    <h3 className="text-base">{cata.name}</h3>
                    <p className="text-xs text-stone-500">0.5 Kilometer Away</p>
                  </Link>
                </div>
                <div className="text-xs text-stone-500">120 Available</div>
              </div>
            </Card>
            // )
          )
        })}
      </div>
    </div>
  )
}

const BrowseByArea = ({ content }) => {
  return (
    <Expandable title="Browse by Area" reverse>
      <ul className="grid gap-2 lg:gap-5 md:grid-cols-3 xl:grid-cols-5">
        {content.map((cata, key) => {
          return (
            <li key={key} className="p-4 rounded-xl border ">
              <Link to={`/guide/area/${cata.slug.current}/`}>
                <span className="block w-full text-base font-medium">
                  {cata.name}
                </span>
                <span className="text-xs text-stone-500">1 Kilometer away</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </Expandable>
  )
}
const MoreCategories = ({ content }) => {
  return (
    <Expandable title="Other Categories">
      <ul className="grid gap-2 lg:gap-5 md:grid-cols-3 xl:grid-cols-5">
        {content.map((cata, key) => {
          return (
            !cata.featured && (
              <li key={key} className="p-4 rounded-xl border ">
                <Link to={`/guide/${cata.slug.current}/`}>
                  <span className="block w-full text-base font-medium">
                    {cata.name}
                  </span>
                  <span className="text-xs text-stone-500">
                    1 Kilometer away
                  </span>
                </Link>
              </li>
            )
          )
        })}
      </ul>
    </Expandable>
  )
}

const CategoryPicture = [
  {
    name: "Beach Club",
    to: "/guide/beach-club/",
    icon: <FaUmbrellaBeach />,
    image: (
      <StaticImage
        src="../images/6b8ffded23f8ba4d03d816de0c43739d.png"
        alt="pic"
        className="w-full h-full object-cover"
      />
    ),
  },
  {
    name: "Sunset Bar",
    to: "/guide/sunset-bar/",
    icon: <FaSun />,
    image: (
      <StaticImage
        src="../images/77226486f8c44c4fd681d8c4d6c7aae8.png"
        alt="pic"
        className="w-full h-full object-cover"
      />
    ),
  },
  {
    name: "Restaurants",
    to: "/guide/restaurants/",
    icon: <FaUtensilSpoon />,
    image: (
      <StaticImage
        src="../images/f4e6ca1d44b7b922f7a853cb42705779.png"
        alt="pic"
        className="w-full h-full object-cover"
      />
    ),
  },
]

export const query = graphql`
  query {
    allSanityArea {
      nodes {
        name
        slug {
          current
        }
      }
    }
    allSanityListingCategory {
      nodes {
        slug {
          current
        }
        name
        featured
        headerImage {
          asset {
            gatsbyImageData
          }
        }
        content {
          _key
          style
          list
        }
        title
        subTitle
        type
        featured
      }
    }
  }
`
